import { ErrorHandler } from '@angular/core';
import { McConfigService } from './mc-config.service';
import { HttpClient } from '@angular/common/http';

export class AppGlobalErrorhandler implements ErrorHandler {
  constructor(private http: HttpClient) {
  }

  async handleError(error) {
    // console.warn('customize catch execption:' + error);
    // if (!error) {
    //   return;
    // }
    console.error(error);
    // const stack_trace = await StackTrace.fromError(error, {offline: true});
    let stack_trace = error.toString();
    if (stack_trace === '[object Object]') {
      if (error.error) {
        if (error.url) {
          stack_trace = { url: error.url, msg: error.error };
        } else {
          stack_trace = { msg: error.error };
        }
      }
    }
    const post_data = {
      stack_trace,
      href: window.location.href,
      ua: navigator.userAgent,
      ts: Date.now()
    };
    this.http.post<any>(`${McConfigService.api_root}/s/client_error`, post_data).subscribe();
  }

}
