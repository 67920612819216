import { Injectable } from '@angular/core';
import { sha256 } from 'js-sha256';
import { AuthService } from '../auth/auth.service';
import { interval, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { McConfigService } from './mc-config.service';


@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private http: HttpClient, private auth: AuthService) {

  }

  /**
   * 定时发送
   * @param key key
   * @param value value
   * @param ms 间隔时间（秒）
   */
  duration(key: string, value: any, ms: number) {
    // 间隔 5s 发送一次online请求，记录使用时长
    interval(ms * 1000).subscribe(n => {
      this.send(key, value);
    });
  }

  /**
   * 延时发送
   * @param key key
   * @param value value
   * @param ms 延时时间（秒）
   */
  delay(key: string, value: any, ms: number) {
    timer(ms * 1000).subscribe(() => {
      this.send(key, value);
    });
  }

  send(key: string, value: any) {
    if (!this.auth.current_user || !this.auth.current_user.user) {
      return;
    }
    const location = window.location;
    const path = location.href.replace(`${location.protocol}//${location.host}`, '');
    const ts = Math.floor(new Date().getTime() / 1000);
    const hash = sha256.create();
    hash.update(`${ts}${key}${value}${this.auth.current_user.user.UserId}${path}`);
    // McHttpService.post<any>(`${McConfigService.api_root}/s/statistics/log`,
    //   {ts, k: key, v: value, sign: hash.hex()})
    //   .subscribe();
    this.http.post<any>(`${McConfigService.api_root}/s/statistics/log`, {ts, k: key, v: value, p: path, sign: hash.hex()}).subscribe();
  }
}
