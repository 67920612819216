import { Inject, Injectable } from '@angular/core';
import { ILabelHref, IMcConfig } from './mc.interface';

// @dynamic
@Injectable({
  providedIn: 'root'
})
export class McConfigService implements IMcConfig {
  static version: number;
  static api_root: string;
  static oss_root?: string;
  static cos_root?: string;
  static wechat_appid: string;

  static app_home: string;
  static app_name: string;
  static app_title: string;
  static agent_dict: Record<string, string>;

  version: number;
  app_name: string;
  app_title: string;
  wechat_appid: string;
  app_home: string;
  api_root: string;
  oss_root: string;
  cos_root?: string;

  copyright: ILabelHref;
  icp_number: ILabelHref;
  agent_dict: Record<string, string>;

  constructor(@Inject('config') private config: IMcConfig) {
    Object.assign(this, config);

    McConfigService.version = config.version;
    McConfigService.app_home = config.app_home;
    McConfigService.api_root = config.api_root;
    McConfigService.oss_root = config.oss_root;
    // 暂时过度，先调通cos，以后再替换
    McConfigService.cos_root = config.cos_root;
    McConfigService.app_name = config.app_name;
    McConfigService.app_title = config.app_title;
    McConfigService.wechat_appid = config.wechat_appid;
    McConfigService.agent_dict = config.agent_dict;
  }

  static get api_host(): string {
    return this.api_root.substring(0, this.api_root.indexOf('/', 9));
  }

}
