import { EventEmitter } from '@angular/core';
import { McHttpService } from './mc-http.service';


// 动作指令
export enum ActionCode {
  DETAIL = 1,    // GET
  INSERT = 2,    // 添加
  UPDATE = 3,    // 更新
  DELETE = 4,    // 删除
  DELETE_ALL = 5,    // 批量删除
  ACTIVE = 6,    // 激活
  CHECK = 7,    // 效验审核
  CHANGE = 8,    // 更改
  SORT_UP = 9,    // 上移
  SORT_DOWN = 10,    // 下移
  SORT_TOP = 11,    // 置顶
  SORT_NO_TOP = 12,    // 置底
  GRID = 13,    // 数据表格
  IMPORT = 14,   // 导入
  EXPORT_EXCEL = 15,   // 导出excel
  DOWNLOAD = 16   // 下载
}

/**
 * 赛选搜索类,主要为datatable服务
 */
export class Filter {
  readonly filter_change: EventEmitter<string>;

  constructor(display_columns: string[]) {
    this.display_columns = display_columns;
    this.filter_change = new EventEmitter<string>();
  }

  display_columns: string[];
  search: string;
  filter: object;
}

export class FastModel {

  _action_: ActionCode;

  constructor(data?: object) {
    if (data) {
      Object.assign(this as object, data);
    }
  }

  http_api_url(): string {
    return '';
  }

  detail(complete_fn?: () => void, fail_fn?: () => void) {
    this._action_ = ActionCode.DETAIL;
    McHttpService.post(this.http_api_url(), this.post_body()).subscribe(data => {
      if (data != null) {
        Object.assign(this as object, data);
        if (complete_fn) {
          complete_fn();
        }
      } else if (fail_fn) {
        fail_fn();
      }
    });
  }

  update(complete_fn?: () => void, fail_fn?: () => void) {
    this._action_ = ActionCode.UPDATE;
    McHttpService.post(this.http_api_url(), this.post_body()).subscribe(data => {
      // Object.assign(this, userData); ide警告
      if (data != null) {
        Object.assign(this as object, data);
        if (complete_fn) {
          complete_fn();
        }
      } else if (fail_fn) {
        fail_fn();
      }
    });
  }

  /**
   * 根据排序规则，移动数据模型
   * @param direction 排序方向
   * @param complete_fn 完成函数
   * @param fail_fn 失败函数
   */
  sort(direction: string, complete_fn?: () => void, fail_fn?: () => void) {
    if (direction === 'up') {
      this._action_ = ActionCode.SORT_UP;
    } else if (direction === 'down') {
      this._action_ = ActionCode.SORT_DOWN;
    } else if (direction === 'top') {
      this._action_ = ActionCode.SORT_TOP;
    } else if (direction === 'no_top') {
      this._action_ = ActionCode.SORT_NO_TOP;
    } else {
      return console.error('移动模型参数设置错误', direction);
    }
    console.log('sort_upsort_upsort', this, direction);
    McHttpService.post(this.http_api_url(), this.post_body()).subscribe(data => {
      if (data != null) {
        Object.assign(this as object, data);
        if (complete_fn) {
          complete_fn();
        }
      } else if (fail_fn) {
        fail_fn();
      }
    });
  }

  delete(complete_fn?: () => void, fail_fn?: () => void) {
    this._action_ = ActionCode.DELETE;
    McHttpService.post(this.http_api_url(), this.post_body()).subscribe(data => {
      if (data != null) {
        Object.assign(this as object, data);
        if (complete_fn) {
          complete_fn();
        }
      } else if (fail_fn) {
        fail_fn();
      }
    });
  }

  // export(complete_fn?: () => void, fail_fn?: () => void) {
  //   this._action_ = ActionCode.GRID;
  //   McHttpService.post(this.http_api_url(), this.post_body()).subscribe(data => {
  //     if (data != null) {
  //       if (complete_fn) {
  //         complete_fn();
  //       }
  //     } else if (fail_fn) {
  //       fail_fn();
  //     }
  //   });
  // }


  action(code: ActionCode, complete_fn?: () => void, fail_fn?: () => void) {
    this._action_ = code;
    McHttpService.post(this.http_api_url(), this.post_body()).subscribe(data => {
      if (data != null) {
        if (complete_fn) {
          complete_fn();
        }
      } else if (fail_fn) {
        fail_fn();
      }
    });
  }


  post_body() {
    const body: object = Object.assign({}, this);
    for (const key of Object.keys(body)) {
      if (key.startsWith('__')) {
        // 默认已"__"开头的变量为前端使用变量，不提交到后台
        delete body[key];
      }
    }
    return body;
  }


}
