<ion-app *ngIf="show" [class.desktop]="is_desktop" [class.mc-mobile]="!is_desktop">
  <!--  [class.welcome-pc]="mcService.device_type=='pc'"-->
  <div class="welcome" [class.welcome-pc]="mcService.device_type==='pc'">
    <img src="assets/img/logo1.png" alt="" class="welcome-logo">

    <div class="footer">
      <span>眉山多能电力建设有限责任公司</span>
      <small></small>
    </div>
  </div>

  <ion-router-outlet id="dn_app_main" [swipeGesture]="mcService.swipe_gesture"></ion-router-outlet>
  <!--  <ion-router-outlet [swipeGesture]="true"></ion-router-outlet>-->
  <!--  <router-outlet name="detail"></router-outlet>-->
</ion-app>
