import { DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { QuillModule } from 'ngx-quill';
import { NgMmcModule } from './ng-mmc.module';
import { MyHammerConfig } from './hammer-config';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { IonicStorageModule } from '@ionic/storage-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxEchartsModule } from 'ngx-echarts';
import zh from '@angular/common/locales/zh';
import { registerLocaleData } from '@angular/common';
import { AppGlobalErrorhandler } from './services/app-global-error-handler';

registerLocaleData(zh);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    HammerModule,
    BrowserAnimationsModule,
    SuperTabsModule.forRoot(),
    QuillModule.forRoot(),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    NgMmcModule.forRoot({
      version: 1001,
      app_title: '多能',
      wechat_appid: 'wx1ac9c673f281add6',
      //wechat_appid: 'ww8a8fcf74ed398eb0',
      app_name: 'dnh5tst',
      // api_root: 'http://dev.dianshida.cn:8057/api',
      app_home: 'https://dev.dianshida.cn',
      api_root: 'https://dev.dianshida.cn/api',
      // oss_root: 'https://duoneng.obs.cn-southwest-2.myhuaweicloud.com/',
      cos_root: 'https://static.msdndj.cn/',
      agent_dict: {video_explorer: '1000176'},
      icp_number: {label: ' ', href: ''},
      copyright: {label: ' ', href: ' '}
    }),
  ],
  providers: [
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'CNY'},
    {provide: LOCALE_ID, useValue: 'zh-CN'},
    {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig},
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    { provide: ErrorHandler, useClass: AppGlobalErrorhandler, deps: [HttpClient] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
