import { IDictionary, INumberDictionary } from './mc.interface';
import { IMcSelectOption } from '../components/mc-select/mc-select-menu.directive';
import { KeyValue } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export class McUtils {
  private static _cid = 0;

  static get make_id() {
    McUtils._cid++;
    return McUtils._cid;
  }

  /**
   * 从结尾比较字符串
   * @param src
   * @param p
   */
  static endswith(src, p) {
    return src.indexOf(p, src.length - p.length) !== -1;
  }

  /**
   * 解析url参数
   * @param query
   */
  static parse_query_string(query?: string): { [key: string]: string } {
    // if the query string is NULL or undefined
    if (!query) {
      query = window.location.search.substring(1);
    }

    const params = {};
    const queries = query.split('&');
    queries.forEach((indexQuery: string) => {
      const indexPair = indexQuery.split('=');

      const queryKey = decodeURIComponent(indexPair[0]);
      params[queryKey] = decodeURIComponent(indexPair.length > 1 ? indexPair[1].split('#')[0] : '');
    });

    return params;
  }

  /**
   * 将数字转成易读的的文件大小
   */
  static format_bytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /**
   * 将数字转成科学记数法
   *
   * @param value 数字
   */
  static format_number(value: string): string {
    const s = `${value}`;
    const list = s.split('.');
    const prefix = list[0].charAt(0) === '-' ? '-' : '';
    let num = prefix ? list[0].slice(1) : list[0];
    let result = '';
    while (num.length > 3) {
      result = `,${num.slice(-3)}${result}`;
      num = num.slice(0, num.length - 3);
    }
    if (num) {
      result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
  }

  /**
   * Deep copy function for TypeScript.
   *
   * @param T Generic type of target/copied value.
   * @param target Target value to be copied.
   * @see Source project, ts-deepcopy https://github.com/ykdr2017/ts-deepcopy
   * @see Code pen https://codepen.io/erikvullings/pen/ejyBYg
   */
  static deep_copy = <T>(target: T): T => {
    if (target === null) {
      return target;
    }
    if (target instanceof Date) {
      return new Date(target.getTime()) as any;
    }
    if (target instanceof Array) {
      const cp = [] as any[];
      (target as any[]).forEach((v) => {
        cp.push(v);
      });
      return cp.map((n: any) => McUtils.deep_copy<any>(n)) as any;
    }
    if (typeof target === 'object' && target !== {}) {
      const cp = {...(target as { [key: string]: any })} as { [key: string]: any };
      Object.keys(cp).forEach(k => {
        cp[k] = McUtils.deep_copy<any>(cp[k]);
      });
      return cp as T;
    }
    return target;
  };

  static is_defined(value: any): boolean {
    return value !== undefined && value !== null;
  }

  static is_object(value: any): boolean {
    return typeof value === 'object' && this.is_defined(value);
  }

  static is_promise(value: any): boolean {
    return value instanceof Promise;
  }

  static is_function(value: any): boolean {
    return value instanceof Function;
  }

  static haskey(object, key) {
    return object ? object.hasOwnProperty(key) : false;
  }

  static getUrlParams(search) {
    const hashes = search.slice(search.indexOf('?') + 1).split('&');
    return hashes.reduce((acc, hash) => {
      // eslint-disable-next-line
      const [key, val] = hash.split('=');
      return {
        ...acc,
        [key]: decodeURIComponent(val)
      };
    }, {});
  }

  // 删除指定item的数组
  static list_remove_item(list: any[], item: any) {
    const index = list.indexOf(item);
    if (index > -1) {
      list.splice(index, 1);
    }
  }

  /**
   * 解决ion-textarea填充数据无法自动适配高度的BUG
   */
  static textarea_auto_height(e) {
    const $target = e.currentTarget as HTMLElement;
    const $textarea = $target.querySelector('textarea') as HTMLElement;
    $textarea.style.height = 'inherit';
    $textarea.style.minHeight = $textarea.scrollHeight + 'px';
    $target.style.minHeight = $textarea.style.minHeight;
  }

  /**
   * 将数组按设置的大小分片
   */
  static chunks<T>(list: T[], size: number): T[][] {
    return list.reduce((all, one, i) => {
      const ch = Math.floor(i / size);
      all[ch] = [].concat((all[ch] || []), one);
      return all;
    }, []);
  }

  /**
   * 反复运行直到返回true
   */
  static loop_run(fn: (i: number) => boolean, timeout?: number, count: number = 0) {
    setTimeout(() => {
      count++;
      if (fn(count) !== true) {
        McUtils.loop_run(fn, timeout, count);
      }
    }, timeout);
  }

  /**
   * 深度合并对象，返回新对象
   */
  static merge_deep(target, ...sources) {
    if (!sources.length) {
      return target;
    }
    const source = sources.shift();

    if (this.is_object(target) && this.is_object(source)) {
      for (const key in source) {
        if (this.is_object(source[key])) {
          if (!target[key]) {
            Object.assign(target, {[key]: {}});
          }
          this.merge_deep(target[key], source[key]);
        } else {
          Object.assign(target, {[key]: source[key]});
        }
      }
    }
    return this.merge_deep(target, ...sources);
  }

  /**
   * 月份增减运算
   *
   * @param month 月日期
   * @param value 向前+1个月 或者 向后-1个月
   */
  static month_delta(month: Date, value: number) {
    const date = new Date(month);
    date.setMonth(date.getMonth() + value);
    date.setDate(1);
    return date;
  }

  /**
   * 2个对象比较
   *
   * @param obj1
   * @param obj2
   */
  static object_equals(obj1: any, obj2: any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  /**
   * 数字大写
   *
   * @param value
   */
  static number_upper(value: number): string {
    if (!value) {
      return '';
    }
    const chn_num_char = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    const chn_unit_char = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千'];
    let chn_str = '';
    let str = value.toString();
    while (str.length > 0) {
      const tmp_uum = chn_num_char[parseInt(str.substr(0, 1), 10)];
      const tmp_char = chn_unit_char[str.length - 1];
      chn_str += (chn_str.substr(-1, 1) === '零' && tmp_uum === '零') ? '' : tmp_uum;
      if (tmp_uum !== '零') {
        chn_str += tmp_char;
      }
      if (chn_str === '一十') {
        chn_str = '十';
      }
      str = str.substr(1);
      if (parseInt(str, 10) === 0) {
        if (str.length >= 8) {
          if (chn_str.substr(-1, 1) !== '亿') {
            chn_str += '亿';
          }
        } else if (str.length >= 5) {
          if (chn_str.substr(-1, 1) !== '万') {
            chn_str += '万';
          }
        }
        str = '';
      }
    }
    return chn_str;
  }

  /**
   * 装换数据未mc-select使用
   *
   * @param items 数据源
   * @param text text字段名
   * @param value value字段名
   * @param filter_mode: 是否在筛选模式使用
   */

  // static mcselect_options(items: IDict<any, any>, text: string, value: string): IMcSelectOption[] ;
  // static mcselect_options(items: IDictionary<any>[], text: string, value: string): IMcSelectOption[] ;
  static mcselect_options(items: IDictionary<any>[], text: string = 'label', value: string = 'id',
                          filter_mode: boolean = false): IMcSelectOption[] {
    const ret: IMcSelectOption[] = items.map(item => {
      return {text: item[text], value: item[value]};
    });
    if (filter_mode) {
      ret.unshift({text: '全部', value: 0, selected: true});
    }
    return ret;
  }

  /**
   * 将列表分组统计的结果装换数据未mc-select使用
   *
   * @param statistics_dict 通过列表接口查询返回的 group_statistics_columns字段具体key的值，也是个字典
   * @param items 数据源
   * @param text text字段名
   * @param value value字段名
   * @param filter_mode: 是否在筛选模式使用
   */

  // static mcselect_options(items: IDict<any, any>, text: string, value: string): IMcSelectOption[] ;
  // static mcselect_options(items: IDictionary<any>[], text: string, value: string): IMcSelectOption[] ;
  static mcselect_options_group_statistics(statistics_dict: INumberDictionary<number>, items: IDictionary<any>[],
                                           text: string = 'label', value: string = 'id',
                                           filter_mode: boolean = false): IMcSelectOption[] {

    const ret: IMcSelectOption[] = [];
    items.forEach(item => {
      console.log('statistics_dict--', statistics_dict, item[value]);
      if (statistics_dict[item[value]]) {
        ret.push({text: `${item[text]}(${statistics_dict[item[value]]})`, value: item[value]});
      }
    });
    if (filter_mode) {
      ret.unshift({text: '全部', value: 0, selected: true});
    }
    return ret;
  }

  /**
   * 通过fields指定的参数，将raw对象的值设置给data
   *
   * @param data
   * @param raw
   * @param fields
   */
  static model_assign<T>(data: T, raw: T, ...fields: string[]) {
    fields.forEach(field => {
      data[field] = raw[field];
    });
  }

  /**
   * 坐标判断，x,y是否在范围内
   * @param x
   * @param y
   * @param $
   */
  static hit_test(x: number, y: number, $: HTMLElement): boolean {
    const rect = $.getBoundingClientRect();
    return rect.x <= x && x <= (rect.x + rect.width) &&
      rect.y <= y && y <= (rect.y + rect.height);
  }

  /**
   * 2个数组进行对比，提取需要添加、删除操作的数据 [adds,dels]
   * @param raw 原始数据
   * @param now 新数据
   */
  static list_diff_action(raw: any[], now: any[]): [any[], any[]] {
    const dels = McUtils.deep_copy(raw);
    const adds = [];
    // 先去除重复项
    now.forEach(item => {
      const raw_index = dels.indexOf(item);
      console.log('raw_index', raw_index);
      if (raw_index >= 0) {
        // 删除重复项---不需要更新
        dels.splice(raw_index, 1);
      } else {
        // 不存在---新建
        adds.push(item);
      }
    });
    return [adds, dels];
  }

  /**
   * 按字典value排序
   *
   * @param dict
   */
  static sort_object_value(dict: KeyValue<string, number>): ([string, number])[] {
    // const items = Object.keys(dict).map(key => [key, dict[key]]);
    const result: ([string, number])[] = [];
    Object.keys(dict).forEach(key => {
      result.push([key, dict[key]]);
    });

    result.sort((first, second) => second[1] - first[1]);
    return result;
  }

  /**
   * 部门分组统计
   * {{"5": "\u4f1a\u8ba1\u6838\u7b97\u4e2d\u5fc3"}:1, {"8": "\u5e02\u573a\u5f00\u62d3\u4e2d\u5fc3"}:2}
   */
  static dep_group_statistics(dep_dict?: IDictionary<string>, filter_mode: boolean = false) {
    if (!dep_dict) {
      return [];
    }
    const dep_count_data: IDictionary<string> = {};
    const deps = {};
    Object.keys(dep_dict).forEach(dep_dict_str_key => {
      if (dep_dict_str_key.startsWith('{') && dep_dict_str_key.endsWith('}')) {
        const count = dep_dict[dep_dict_str_key];
        const data = JSON.parse(dep_dict_str_key);
        Object.keys(data).forEach(dep_id => {

          if (!dep_count_data[dep_id]) {
            dep_count_data[dep_id] = count;
            deps[dep_id] = data[dep_id];
          } else {
            dep_count_data[dep_id] += count;
          }
        });
      }
    });
    const ret: IMcSelectOption[] = [];
    Object.keys(dep_count_data).forEach(dep_id => {
      // ret.push({ text: `${deps[dep_id]}(${dep_count_data[dep_id]})`, value: dep_id });
      ret.push({text: deps[dep_id], value: dep_id});
    });
    if (filter_mode) {
      ret.unshift({text: '全部', value: 0, selected: true});
    }
    return ret;
  }

  /**
   * 生成缩略图
   * @param file： input-file
   * @param box  (width,height)
   */
  static thumbnail_file(file, box: [number, number]) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error('Context not available');
    }
    return new Promise<string>((resolve, reject) => {
      const img = new Image();
      img.onerror = reject;
      img.onload = () => {
        const scaleRatio = Math.min(...box) / Math.max(img.width, img.height);
        const w = img.width * scaleRatio;
        const h = img.height * scaleRatio;
        canvas.width = w;
        canvas.height = h;
        ctx.drawImage(img, 0, 0, w, h);
        return resolve(canvas.toDataURL(file.type));
      };
      img.src = window.URL.createObjectURL(file);
    });
  }

  /**
   * 生成缩略图
   * @param src 图片url | base64
   * @param box (width,height)
   * @param type image/png | image/jpeg
   * @param quality 如果是jpg 压缩率
   */
  static thumbnail_img(src: string, box: [number, number], type = 'image/jpeg', quality = 0.6) {
    console.log(32191111);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    console.log(32191222, ctx);
    if (!ctx) {
      throw new Error('Context not available');
    }
    console.log(32191333);
    return new Promise<string>((resolve, reject) => {
      console.log(32191444);
      const img = new Image();
      img.setAttribute('crossOrigin', 'Anonymous');
      img.onerror = reject;
      console.log(32191555);
      // return resolve(src);
      img.onload = () => {
        console.log(32191666);
        const scale = Math.min(...box) / Math.max(img.width, img.height);
        const w = img.width * scale;
        const h = img.height * scale;
        canvas.width = w;
        canvas.height = h;
        ctx.drawImage(img, 0, 0, w, h);
        if (type === 'image/jpeg') {
          return resolve(canvas.toDataURL(type, quality));
        }
        return resolve(canvas.toDataURL());
      };
      img.src = src;
    });
  }

  /**
   * 上传Base64文件
   *
   * @param http httpclient
   * @param b64 文件内容base64
   * @param action 上传服务端的api地址
   * @param form_data 扩展数据字段，只支持{str:str}
   */
  post_upload_file = (http: HttpClient, b64: string, action: string, form_data?: Record<string, any>) => {
    // const headers = new Headers({ 'enctype': 'multipart/form-data' });
    // // headers.append('Accept', 'application/json');
    // const options = new RequestOptions({ headers: headers });
    // const headers = new Headers();
    // headers.append('enctype', 'multipart/form-data');
    const formData: FormData = new FormData();
    formData.append('file', b64);
    if (form_data) {
      Object.keys(form_data).forEach(key => {
        formData.append(key, form_data[key]);
      });
    }
    console.log('before hist the service' + formData);
    return http.post(action, formData)
      .pipe(map(ret => {
          console.log('cvsfsd==', ret);
        })
      );
  };
}


