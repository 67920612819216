import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home-routing.module').then(m => m.HomePageRoutingModule)
  // },
  {
    path: '404',
    loadChildren: () => import('./home/page404/page404.module').then(m => m.Page404PageModule)
  },
  {
    path: '403',
    loadChildren: () => import('./home/page403/page403.module').then(m => m.Page403PageModule)
  },
  {
    path: 'pg',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    redirectTo: '404',
    pathMatch: 'full'
  },
  {
    path: 'tabs-main',
    loadChildren: () => import('./newtest/tabs-main/tabs-main.module').then(m => m.TabsMainPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
