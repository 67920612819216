import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { McConfigService } from './services/mc-config.service';
import { McService } from './services/mc-service';
import { GhostRouteService } from './services/ghost-route.service';
import { StatisticsService } from './services/statistics.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  // swipe_gesture = false;
  show = false;
  is_desktop = false;

  constructor(
    private platform: Platform,
    // private statusBar: StatusBar,
    private mcConfig: McConfigService,
    public mcService: McService,
    private previousRoute: GhostRouteService,
    private statistics: StatisticsService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    document.body.setAttribute('data-theme', 'light');
    document.body.classList.toggle('dark', false);
    // SplashScreen.hide().then(() => {
    //   console.log('SplashScreenHide')
    // });

    this.platform.ready().then(() => {
      this.is_desktop = this.platform.platforms().some(item => item === 'desktop');
      if (this.is_desktop) {
        document.body.classList.add('dark-color');
      } else {
        // 移动端使用jssdk
      }

      // this.swipe_gesture = this.platform.platforms().some(item => item === 'android');
      // console.log('set-swipe_gesture', this.swipe_gesture);
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
    });

    this.check_browser();
    // 时长统计
    if (window.location.host.indexOf('127.0.0.1:810') === 0 || window.location.host.indexOf('192.168.') === 0 ||
      window.location.host.indexOf('dev.dianshida.cn') === 0) {
      this.statistics.duration('online', 155, 155);
    } else {
      this.statistics.duration('online', 30, 30);
    }
  }

  check_browser() {
    if (window.navigator.userAgent.indexOf('wxwork/') > 1 || window.location.host.indexOf('127.0.0.1:810') === 0
      || window.location.host.indexOf('192.168.') === 0 || window.location.host.indexOf('dev.dianshida.cn') === 0) {
      this.show = true;
    } else if (window.navigator.userAgent.indexOf('webdebugger port/') > 1) {
      this.show = true;
    } else {
      // console.log('check_browser::', window.navigator.userAgent, window.location.host);
    }
  }


}
