import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { McHttpService } from './mc-http.service';
import { McMessageService } from './mc-message.service';
import { LoadingController } from '@ionic/angular';
import { ActionCode } from './mc-fastmodel.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class McService {
  // 记录客户端最大的高度，用来解决安卓输入法暂用高度的问题
  client_max_height = 0;
  is_loading = false;
  // 是否启用手势
  swipe_gesture: boolean;
  // 是否是移动端
  private displayMobileSubject: BehaviorSubject<boolean>;
  // 窗体大小发生变化
  private resizeSubject: Subject<{ width: number; height: number }>;
  // 当前loading组件
  private current_loading_ctrl: HTMLIonLoadingElement | null;

  constructor(public http: HttpClient, public mcMessage: McMessageService, public loadingController: LoadingController) {
    // console.log('McService--init--111');
    this.displayMobileSubject = new BehaviorSubject<boolean>(null);
    McHttpService.init(http, mcMessage);
    this.client_max_height = document.documentElement.clientHeight || document.body.clientHeight;
    this.resizeSubject = new Subject();

    this.swipe_gesture = this.device_type === 'android';
    // console.log('set-swipe_gesture', this.swipe_gesture);
    // this.event_manager.getZone().runOutsideAngular(() => {
    //   this.event_manager.addGlobalEventListener('window', 'resize', this.onResize.bind(this));
    // });

    window.addEventListener('resize', this.onResize.bind(this));
    // this.onResize();
    // window.removeEventListener()
  }

  get resize_obs(): Observable<{ width: number; height: number }> {
    return this.resizeSubject.asObservable();
  }

  get today() {
    return new Date();
  }

  get min_now() {
    const d = new Date();
    d.setDate(d.getDate() - 365);
    return d;
  }

  get max_now() {
    const d = new Date();
    d.setDate(d.getDate() + (365 * 5));
    return d;
  }

  /**
   * 获取设备类型 android | ios
   */
  get device_type(): 'ios' | 'android' | 'pc' {
    const ua = window.navigator.userAgent.toLocaleLowerCase();
    if (/iphone|ipad|ipod/.test(ua)) {
      return 'ios';
    }
    if (/android/.test(ua)) {
      return 'android';
    }
    if (ua.indexOf('mac os') > 0 || ua.indexOf('windows') > 0) {
      return 'pc';
    }
  }


  onResize(event?) {
    // console.log(window.innerHeight, window.innerHeight, this, event, 'onresize--s-');
    this.resizeSubject.next({
      width: window.innerWidth,
      height: window.innerHeight
    });
    this.client_max_height = Math.max(this.client_max_height, window.innerHeight);
    // if (this.device_type === 'android' || 1 === 1) {
    //   this.client_max_height = Math.max(this.client_max_height, window.innerHeight);
    //   this.client_max_height = window.innerHeight;
    //   const root_element = document.querySelector('ion-app');
    //   console.log('onresize--sss-', root_element, this.client_max_height);
    //   if (root_element) {
    //     root_element.style.height = this.client_max_height + 'px';
    //   }
    // }
    //
    // if (window.innerWidth < 1024) {
    //   this.displayMobileSubject.next(true);
    //   // console.log('set displayMobileSubject true');
    // } else {
    //   this.displayMobileSubject.next(false);
    // }
  }

  set_ionapp_full_height() {
    if (this.device_type === 'android') {
      this.client_max_height = Math.max(this.client_max_height, window.innerHeight);
      const root_element = document.querySelector('ion-app');
      console.log('onresize---', root_element, window.innerHeight);
      if (root_element) {
        root_element.style.height = this.client_max_height + 'px';
      }
    }
  }


  trim(str: string) {
    if (!str) {
      return str;
    }
    const reg = /^\s+|\s+$/g;
    return str.replace(reg, '');
  }

  newline(str: string, nbsp?: boolean) {
    if (str && nbsp) {
      console.log('newline1', str);
      return str.replace(/\n/g, '<br>').replace(/\s/g, '&nbsp;');
    }
    if (str) {
      return str.trim().replace(/\n/g, '<br>');
    }
    return '';
  }

  async loading_present(message?: string, duration?: number) {
    // console.log('current_loading_ctrl--1', this.current_loading_ctrl);
    if (this.current_loading_ctrl) {
      this.current_loading_ctrl.message = message;
      this.current_loading_ctrl.duration += duration || 30000;
      return;
    }
    this.is_loading = true;
    return await this.loadingController.create({
      message: message || '正在加载.',
      duration: duration || 30000,
    }).then(ctrl => {
      this.current_loading_ctrl = ctrl;
      ctrl.present().then(() => {
        if (!this.is_loading) {
          this.current_loading_ctrl = null;
          ctrl.dismiss().then();
        }
      });
    });
  }

  async loading_dismiss() {
    if (!this.is_loading) {
      return;
    }
    this.is_loading = false;
    this.current_loading_ctrl = null;
    return await this.loadingController.dismiss().then();
  }

  alert(msg: string, duration = 2000) {
    this.mcMessage.alert(msg, duration).then();
  }

  /**
   * 通过文件名判断文件类型，返回文件类型图标名称
   */
  file_type_icon(name: string): string {
    const root = '../../../../assets/icon/filetype/';
    if (!name) {
      return root + 'link.svg';
    }
    const filename = name.toLowerCase();
    if (['.png', '.jpg', '.jpeg', '.gif'].some(type => filename.indexOf(type) > 0)) {
      return root + 'file-image.svg';
    }
    if (['.mp4', '.mkv', '.3gp'].some(type => filename.indexOf(type) > 0)) {
      return root + 'file-video.svg';
    }
    if (filename.indexOf('.pdf') > 0) {
      return root + 'file-pdf.svg';
    }
    if (filename.indexOf('.doc') > 0) {
      return root + 'file-word.svg';
    }
    if (filename.indexOf('.wps') > 0) {
      return root + 'file-word.svg';
    }
    if (filename.indexOf('.xls') > 0) {
      return root + 'file-spreadsheet.svg';
    }
    if (filename.indexOf('.ppt') > 0) {
      return root + 'file-powerpoint.svg';
    }
    if (filename.indexOf('.doc') > 0) {
      return root + 'file-word.svg';
    }
    if (filename.indexOf('.txt') > 0) {
      return root + 'file-alt.svg';
    }
    return root + 'link.svg';
  }

  /**
   * 基于FastModel规则，导出xls
   *
   * @param api FastModel Api地址
   * @param request_data post数据，筛选参数加载udata对象中
   */
  export_excel(api: string, request_data: any) {
    return new Promise<boolean>((resolve, reject) => {
      this.loading_present('正在提交', 5 * 60 * 1000).then(() => {
        McHttpService.post<any>(api, request_data)
          .subscribe(data => {
            if (data && data.key) {
              const xls_url = `${api}?_action_=${ActionCode.DOWNLOAD}&key=${data.key}`;
              if (this.device_type === 'android' || this.device_type === 'pc') {
                window.open(xls_url);
              } else {
                // iphone 用重定向
                window.location.href = xls_url;
              }
              this.loading_dismiss().then();
              resolve(true);
            }
            resolve(false);
          });
      });
    });
  }

  window_open() {
    window.open('https://static.msdndj.cn/static/repoert/1.xls', '_system');
  }

  /**
   * 日期计算 sign_date_gt
   */
  set_date(data: any, field: string, flag: 'year' | 'month' | 'week' | 'current_year' | 'current_month' | 'current_week', value: number) {
    if (flag === 'current_week') {
      data[field] = moment().startOf('week').toISOString();
    } else if (flag === 'current_month') {
      data[field] = moment().startOf('month').toISOString();
    } else if (flag === 'current_year') {
      data[field] = moment().startOf('year').toISOString();
    } else if (flag === 'month') {
      data[field] = moment().subtract(value, 'M').toISOString();
    } else if (flag === 'year') {
      // if (value === 0) {
      //   this.project_list.filter.sign_date_gt = moment().subtract(value, 'M').toISOString();
      // }
    }
  }
}
