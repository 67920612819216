import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class McMessageService {

  constructor(private toast_ctrl: ToastController, public alert_ctrl: AlertController) {
  }

  async create(type: 'error' | 'warn' | 'success', msg: string, duration = 3000,
               position: 'middle' | 'top' | 'bottom' = 'middle', fn?: () => void) {
    let color = 'primary';
    if (type === 'error') {
      if (msg.length > 100) {
        msg = msg.substr(0, 100);
      }
      color = 'danger';
    } else if (type === 'warn') {
      color = 'warning';
    } else if (type === 'success') {
      color = 'success';
    }
    const toast = await this.toast_ctrl.create({
      // header: 'Toast header',
      message: msg,
      duration,
      position,
      cssClass: 'mc-' + type,
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
        }
      ],
      color
    });
    // toast.onDidDismiss(() => {
    //   console.log('Dismissed toast');
    // });
    await toast.present();
    // await toast.dismiss();
    if (fn) {
      toast.onDidDismiss().then(() => {
        fn();
      });
    }
  }

  error(msg, duration = 3000) {
    // 如果内容太长支取前100个
    if (msg.length > 100) {
      msg = msg.substr(0, 100);
    }
    return this.create('error', msg, duration);
  }

  async confirm(msg: string, ok_fn: (value: any) => void) {
    const ctl = await this.alert_ctrl.create({
      cssClass: 'my-custom-class',
      header: '请确认!',
      message: msg,
      buttons: [
        {
          text: '确认',
          handler: ok_fn
        }, {
          text: '取消',
          role: 'cancel',
        }
      ]
    });
    await ctl.present();
  }

  async alert(msg: string, duration = 2000, position: 'middle' | 'top' | 'bottom' = 'middle') {
    const toast = await this.toast_ctrl.create({
      // header: 'Toast header',
      message: msg,
      duration,
      position,
      cssClass: 'mc-alert',
    });
    // toast.onDidDismiss(() => {
    //   console.log('Dismissed toast');
    // });
    await toast.present();
  }
}
