import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class McStorageService {

  private store: Storage | null = null;

  constructor(private storage: Storage) {
    // this.init().then();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    this.store = await this.storage.create();
  }

  async set(key: string, value: any) {
    if (!this.store) {
      await this.init();
    }
    // console.log('storage set', key, value, this.store);
    await this.store?.set(key, value);
  }

  async gets(key: string): Promise<{ value: any }> {
    // console.log('zzz', this.store);
    return await this.store?.get(key);
  }

  async get(key: string): Promise<any> {
    if (!this.store) {
      await this.init();
    }
    const ret = await this.gets(key);
    // console.log('storage get', key, ret, this.store);
    if (!ret) {
      return null;
    }
    // return JSON.parse(ret.value);
    return ret;
  }

  async remove(key: string) {
    await this.store?.remove(key);
  }

  async clear() {
    await this.store?.clear();
  }
}
