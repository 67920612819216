import { ModuleWithProviders, NgModule } from '@angular/core';
import { McConfigService } from './services/mc-config.service';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { IMcConfig } from './services/mc.interface';
import { McService } from './services/mc-service';
import { McMessageService } from './services/mc-message.service';
import { LoadingController } from '@ionic/angular';

export { McGridSourceService } from './services/mc-grid.source.service';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [
    // {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {
      provide: McService, useFactory: (http: HttpClient, mcMessage: McMessageService, loading: LoadingController) => {
        return new McService(http, mcMessage, loading);
      },
      deps: [HttpClient, McMessageService, LoadingController],
    },
  ]
})
export class NgMmcModule {
  static forRoot(config: IMcConfig): ModuleWithProviders<NgMmcModule> {
    return {
      ngModule: NgMmcModule,
      providers: [McConfigService, {provide: 'config', useValue: config}]
    };
  }
}

