// 状态指令

export enum StatusCode {
  // """ 成功 """
  SUCCESS = 0,
  // """ 失败 """
  FAIL = 1,
  // """ 错误 """
  ERROR = 2,
  // """ 存在 """
  EXIST = 3,
  // """ 不存在 """
  NOTEXIST = 4
}

