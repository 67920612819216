import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { StatusCode } from './code';
import { McMessageService } from './mc-message.service';

export interface IHttpApiRet<T> {
  _code_: number;
  _msg_?: string;
  data?: T;
}

// 必须不然打包报错
// @dynamic
export class McHttpService {
  public static http: HttpClient;
  public static message: McMessageService;

  static init(httpClient: HttpClient, mcMessage: McMessageService) {
    // console.log('http--init');
    this.http = httpClient;
    this.message = mcMessage;
  }

  static upload__<T>(url, file, args?: object) {
    const form_data = new FormData();
    form_data.append('file', file);
    if (args) {
      Object.keys(args).forEach(item => {
        form_data.append(item, args[item]);
      });
    }
    const req = new HttpRequest('POST', url, form_data, {
      // reportProgress: true
    });
    return this.http
      .request<T>(req)
      .pipe(
        filter(e => e instanceof HttpResponse),
        map(result => {
          console.log('mmmap', result);
          console.log('mmmap', result['body']);
          return result['body'];
        }),
        catchError(error => {
          const status: number = error.status;
          console.log('upload1001', error);
          this.message.create('error', `HTTP ${status} – 文件上传失败`);
          return of(null);
        })
      );
  }

  /**
   * 上传base64文件
   * @param url
   * @param b64
   * @param args
   */
  static upload_baee64<T>(url, b64: string, args?: object) {
    const form_data = new FormData();
    form_data.append('file', b64);
    if (args) {
      Object.keys(args).forEach(item => {
        form_data.append(item, args[item]);
      });
    }
    const req = new HttpRequest('POST', url, form_data, {
      // reportProgress: true
    });
    return this.http
      .request<T>(req)
      .pipe(
        filter(e => e instanceof HttpResponse),
        map(result => {
          console.log('mmmap', result);
          console.log('mmmap', result['body']);
          return result['body'];
        }),
        catchError(error => {
          const status: number = error.status;
          console.log('upload1001', error);
          this.message.create('error', `HTTP ${status} – 文件上传失败`);
          return of(null);
        })
      );
  }


  /**
   * 静态方法post
   *
   * @param url api地址
   * @param body request字段
   * @param header http头
   * @param raw 是否返回原始response对象,默认false
   * @param alert_fn 异常提示处理方法,默认this.message.error
   */
  static post<T>(url: string, body?: object, header?: object, raw?: boolean, alert_fn?: (msg: string) => void) {
    if (!alert_fn) {
      alert_fn = this.message.error;
    }
    const head = {
      'Content-Type': 'text/plain',
    };
    if (header) {
      Object.assign(head, header);
    }
    const options = {
      headers: new HttpHeaders(head)
    };
    // console.log(url, body, 'send>', Http.http_client.post);
    const h = this.http.post<IHttpApiRet<T>>(url, body, options);
    return h.pipe(
      tap((userData) => {
        // console.log('tap', userData, this.message.create);
      }),
      // startWith(() => {
      //     console.log('xxxx1');
      // }),
      // filter(x => {
      //   console.log('fff', x);
      //   return x;
      // }),
      switchMap(result => {
        if (!result || !result._code_ === undefined) {
          return [null];
        }
        if (result._code_ !== StatusCode.SUCCESS) {
          console.log('http-result', result);
          // 返回状态不是完成状态
          if (result._code_ >= 10 && result._code_ <= 12) {
            // window.location.href = '/auth/relogin';
            console.log('loggggout-post');
          } else {
            // alert_fn(result._msg_);
            this.message.create('error', result._msg_).then();
            // Dialog.warning(result._msg_);
          }
          // console.log('http result >> ', result);
        }
        // ok
        return [result];
      }),
      map(result => {
        // console.log('remap', result);
        if (raw) {
          return result;
        }
        if (result._code_ !== 0) {
          return null;
        }
        // console.log('result.data', result.data);
        if (result.data) {
          return result.data;
        } else {
          return {};
        }
      }),
      catchError(error => {
        const status: number = error.status;
        console.log('1001', error);
        if (status >= 400 && status < 500) {
          this.message.create('error', `HTTP ${status} – 无法找到文件`);
        } else if (status >= 500 && status < 600) {
          this.message.create('error', `HTTP ${status} – 内部服务器错误`);
        } else if (status === 0) {
          this.message.create('error', `远程服务器连接失败`);
        } else {
          this.message.create('error', `未知错误 ${status} ${error.statusText}`);
        }
        return of(null);
      })
    );
  }

  static get<T>(url: string, params?: { [param: string]: string | string[] }, header?: object) {
    const head = {
      'Content-Type': 'text/plain',
    };
    if (header) {
      Object.assign(head, header);
    }
    const options = {
      headers: new HttpHeaders(head),
      params
    };
    const h = this.http.get<IHttpApiRet<T>>(url, options);
    return h.pipe(
      map(result => {
        if (!result || !result._code_ === undefined) {
          return null;
        }
        if (result._code_ !== StatusCode.SUCCESS) {
          console.log('getresult', result);
          // 返回状态不是完成状态
          if (result._code_ >= 10 && result._code_ <= 12) {
            // window.location.href = ;
            const new_url = '/auth/logout?returnUrl=' + encodeURIComponent(window.location.href);
            console.log('loggggout--get', new_url);
            window.location.href = new_url;
          } else {
            this.message.create('error', result._msg_).then();
            // Dialog.warning(result._msg_);
          }
          // console.log('http result >> ', result);
          return null;
        }
        // ok
        return result.data;
      }),
      catchError(error => {
        const status: number = error.status;
        console.log('1001', error);
        if (status >= 400 && status < 500) {
          this.message.create('error', `HTTP ${status} – 无法找到文件`);
        } else if (status >= 500 && status < 600) {
          this.message.create('error', `HTTP ${status} – 内部服务器错误`);
        } else if (status === 0) {
          this.message.create('error', `远程服务器连接失败`);
        } else {
          this.message.create('error', `未知错误 ${status} ${error.statusText}`);
        }
        return of(null);
      })
    );
  }

}
