import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class GhostRouteService {
  // 上一个页面url地址
  public previous_url: string;
  // 当前页面url地址
  public url: string;
  // url历史路径
  public history: string[] = [];

  private readonly url_events: { [key: string]: { ev: EventEmitter<any>; args: any; used?: boolean } };

  constructor(private router: Router, private navCtrl: NavController) {
    this.url = this.router.url;
    this.previous_url = this.url;
    this.url_events = {};
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.previous_url = this.url;
        this.url = event.url;
        const ev_opt = this.url_events[this.url];
        // console.log('root-url:', event.url, ' referer:', this.previous_url, ' ev_opt:', ev_opt);
        if (ev_opt && !ev_opt.used) {
          ev_opt.ev.emit(ev_opt.args);
          ev_opt.used = true;
        }
      } else if (event instanceof NavigationEnd) {
        const index = this.history.indexOf(event.url);
        if (index > -1) {
          this.history = this.history.slice(0, index);
          // console.log('this.history--2', this.history);
        }
        this.history.push(event.url);
        console.log('this.history--end', this.history, this.history.indexOf(event.url));
      }
    });
  }

  public add_listener(url?: string) {
    if (!url) {
      url = this.url;
    }
    if (!this.url_events[url]) {
      this.url_events[url] = { ev: new EventEmitter(), args: null };
    }
    return this.url_events[url].ev;
  }

  public set_listener(url: string, args: any) {
    const ev_opt = this.url_events[url];
    if (ev_opt) {
      ev_opt.args = args;
      // 标记事件未使用
      ev_opt.used = false;
    }
    // console.log(' this.url_events', this.url_events);
  }

  public remove_listener(url?: string) {
    if (!url) {
      url = this.url;
    }
    delete this.url_events[url];
  }

  go_back(url: string, index?: number) {
    // console.log('go_back', url, this.history, index);
    if (url === 'go_index' && index) {
      window.history.go(index);
      this.history = this.history.slice(0, index);
    } else {
      // const a = this.history.find((item, i) => {
      //   return item === url;
      // });
      index = this.history.indexOf(url);
      // console.log('go_back2', url, index, window.history, this.history);
      if (index >= 0) {
        window.history.go(index - this.history.length + 1);
        this.history = this.history.slice(0, index);
      } else {
        // 不在history中；重新导航
        // this.router.navigate([url], {replaceUrl: true});
        // 2023-11-06
        // this.router.navigate([url]);
        // console.log('go_back3', url);
        this.navCtrl.navigateRoot([url], { animationDirection: 'back' });
      }
    }
  }

  back() {
    this.go_back('go_index', -1);
  }
}
